<template>
    <div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            :loading="loading"
            max-height="700"
            stripe
        >
            <template slot-scope="{ row }" slot="company">
                {{ row.company ? row.company.company_name_en : '' }}
            </template>
            <template slot-scope="{ row }" slot="eod_status">
                <span
                    class="expand-value tw-ml-2"
                    :class="statusStyle(row.eod_status)"
                    >{{ row.eod_status ? row.eod_status : '' }}</span
                >
            </template>
            <template slot-scope="{ row }" slot="action">
                <a
                    v-if="row.eod_status == 'Success'"
                    href="#"
                    class="text-primary ml-4"
                    @click.prevent="onRollback(row)"
                    v-tooltip="$t('eod.rollback')"
                >
                    <Icon type="ios-sync" size="20" />
                </a>
                <a
                    v-if="row.eod_status == 'Pending'"
                    href="#"
                    class="text-danger ml-4"
                    @click.prevent="onClose(row)"
                    v-tooltip="$t('eod.close')"
                >
                    <Icon type="ios-close-circle-outline" size="20" />
                </a>
            </template>
        </Table>
        <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
            <ts-i-pagination
                v-model="pagination"
                @navigate="fetchData"
            ></ts-i-pagination>
        </div>
        <!-- form-action -->
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('eod.rollback')"
            width="400"
        >
            <from-action
                ref="form_action"
                @cancel="clearEdit"
                @fetchData="fetchData"
            />
        </Modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FromAction from './form'

export default {
    name: 'eodIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false
        }
    },
    created () {
        this.fetchData()
    },
    computed: {
        ...mapState('creditOperation/eod', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.creditOperation.eod.search
            },
            set (newValue) {
                this.$store.commit('creditOperation/eod/SET_SEARCH', newValue)
                this.$store.commit('creditOperation/eod/RESET_CURRENT_PAGE')
            }
        },
        statusStyle: () => status => {
            let badge = ''

            switch (status) {
                case 'Pending':
                    badge = 'badge bg-info'
                    break
                case 'Success':
                    badge = 'badge bg-primary'
                    break
            }

            return badge
        },
        columns () {
            return [
                {
                    title: this.$t('eod.company'),
                    slot: 'company',
                    sortable: false
                },
                {
                    title: this.$t('eod.eodDate'),
                    key: 'eod_date',
                    sortable: false
                },
                {
                    title: this.$t('eod.eodStatus'),
                    slot: 'eod_status',
                    align: 'center',
                    filters: [
                        {
                            value: 'Pending',
                            label: 'Pending'
                        },
                        {
                            value: 'Success',
                            label: 'Success'
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                eod_status: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/eod/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('creditOperation/eod/SET_EDIT_DATA', {})
        },
        onRollback (record) {
            this.$store.commit('creditOperation/eod/SET_EDIT_DATA', record)
            this.showForm = true
            this.$refs.form_action.setEditData()
        },
        onClose (record) {
            record._deleting = true
            this.$store
                .dispatch('creditOperation/eod/close', {
                    eod_date: record.eod_date
                })
                .then(response => {
                    this.fetchData()
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'EOD',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/eod/RESET_STATE')
        next()
    }
}
</script>
